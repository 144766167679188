module.exports = {
    "dashboard": "계기반",
    "myAccounts": "내 크레딧",
    "internationalTransfer": "국제 송금",
    "fundingType": "카드 및 계정",
    "deposit": "구매 크레딧",
    "withdraw": "빼내다",
    "invoice": "송장",
    "requestMoney": "금액 요청",
    "recipients": "받는 사람",
    "developers": "개발자",
    "myProfile": "내 프로필",
    "logout": "로그 아웃",
    "qucikBooks": "퀵북",
    "profileDetails": "프로필 세부정보",
    "password": "비밀번호",
    "users": "사용자",
    "qbDownloads": "QB 다운로드",
    "transfer": "옮기다",
    "allTransactions": "내 거래",
    "recurringTrans": "반복 거래",
    "firstName": "이름",
    "lastName": "성",
    "emailAddress": "이메일 주소",
    "userRole": "사용자 역할",
    "settings": "설정",
    "oldPass": "기존 비밀번호",
    "newPass": "새 비밀번호",
    "confirmPass": "비밀번호 확인",
    "passwordRequirements": "암호 요구 사항",
    "reqOne": "최소 8자",
    "reqTwo": "적어도 하나의 소문자",
    "reqThree": "적어도 하나의 대문자",
    "reqFour": "적어도 하나의의 숫자",
    "reqFive": "적어도 하나의 특수 문자",
    "reqSix": "적어도 하나의 특수 문자",
    "reqSeven": "공백 없음",
    "saveChanges": "변경 사항을 저장하다",
    "onlyIntAccounts": "해외 계정만",
    "availableBalance": "사용 가능한 잔액",
    "currentBalance": "현재의 잔액",
    "lastUpdatedDate": "마지막 업데이트 날짜",
    "channel": "채널",
    "webhook": "웹훅",
    "recipientName": "받는 사람 이름",
    "aCHAccountNumber": "ACH 계좌 번호",
    "wireAccountNumber": "송금 계좌 번호",
    "aPIKey": "API 키",
    "clientName": "고객 이름",
    "createdBy": "작성자",
    "transactionID": "트랜잭션 ID",
    "sourceType": "소스 유형",
    "accountReference": "계정 참조",
    "routingReference": "라우팅 참조",
    "expiryDate": "만료일",
    "recipientEmail": "이메일 수령인",
    "creationDate": "생산 일",
    "startDate": "시작일",
    "reference": "참조",
    "transactionType": "거래 유형",
    "name": "이름",
    "phone": "핸드폰",
    "role": "역할",
    "complete": "완벽한",
    "failure": "실패",
    "returns": "반납하다",
    "count": "세다",
    "accounts": "계정",
    "noAccounts": "계정 없음",
    "transactionsType": "거래 유형",
    "mostRecentTransactions": "가장 최근 거래",
    "export": "내보내다",
    "transactionHistory": "거래 내역",
    "transactionReference": "트랜잭션 참조",
    "parentReference": "상위 참조",
    "paymentReference": "결제 참조",
    "amount": "양",
    "invoiceNumber": "송장 번호",
    "feeAmount": "요금",
    "taxAmount": "세액",
    "exciseTax": "소비세",
    "salesTax": "판매세",
    "registrationFee": "등록비",
    "serviceFee": "서비스 요금",
    "conversionRate": "전환율",
    "oFACMatch": "OFAC 경기",
    "type": "유형",
    "recurringReference": "반복 참조",
    "status": "상태",
    "created": "만들어진",
    "account": "계정",
    "receiver": "수화기",
    "description": "설명",
    "paymentType": "지불 유형",
    "from": "에서",
    "sendVia": "통해 보내기",
    "recipientEmailAddress": "받는 사람 이메일 주소",
    "recipientPhoneNumber": "받는 사람 전화번호",
    "recipientAccountNumber": "수취인 계좌 번호",
    "confirmAccountNumber": "계좌번호 확인",
    "to": "에게",
    "currency": "화폐",
    "direction": "방향",
    "paymentDate": "결제일",
    "service": "서비스",
    "frequency": "빈도",
    "endDate": "종료 날짜",
    "numberofTransfers": "전송 횟수",
    "internalDescription": "내부 설명",
    "accountNumber": "계좌 번호",
    "pc_accountNumber": "계좌 번호",
    "fileName": "파일 이름",
    "recipient_email": "이메일 수령인",
    "createdDate": "만든 날짜",
    "dueDate": "마감일",
    "memo": "메모",
    "action": "행동",
    "email": "이메일",
    "phoneNumber": "전화 번호",
    "bankName": "은행 이름",
    "recipientType": "받는 사람 유형",
    "fromAccountNumber": "계정 번호에서",
    "nameonCard": "카드의 이름",
    "zipCode": "우편 번호",
    "accountName": "계정 이름",
    "routingNumber": "라우팅 번호",
    "accountType": "계정 유형",
    "address": "주소",
    "city": "도시",
    "country": "국가",
    "state": "주",
    "verificationType": "확인 유형",
    "debitAmount": "직불 금액",
    "firstCreditAmount": "첫 크레딧 금액",
    "secondCreditAmount": "두 번째 크레딧 금액",
    "nickName": "닉네임",
    "beneficiaryBankCode": "수취인 은행 코드",
    "addressLine1": "주소 1",
    "addressLine2": "주소 2",
    "paymentMethod": "지불 방법",
    "referenceID": "참조 ID",
    "aCHDetails": "ACH 세부 정보",
    "wireDetails": "와이어 세부 정보",
    "internationalTransferDetails": "국제 송금 세부 사항",
    "selectDate": "날짜 선택",
    "sender": "보내는 사람",
    "remarks": "비고",
    "buyorSell": "구매 또는 판매",
    "toAccountNumber": "계좌번호로",
    "paymentInstrument": "결제 수단",
    "fromaccount": "계좌로부터",
    "toCounterparty": "상대방에게",
    "purposeofPayment": "지불의 목적",
    "fXRate": "환율",
    "fXAmount": "외환 금액",
    "fXCurrency": "외환 통화",
    "toName": "이름을 지정하려면",
    "toAccount": "계정에",
    "nextTransactionDate": "다음 거래일",
    "aPICode": "API 코드",
    "publicKey": "공개 키",
    "privateKey": "개인 키",
    "optional": "선택 과목",
    "additionalInformation": "추가 정보",
    "createPayment": "결제 생성",
    "downloadPDF": "PDF 다운로드",
    "resend": "재전송",
    "approve": "승인하다",
    "decline": "취소",
    "addCard": "카드 추가",
    "addBankAccount": "은행 계좌 추가",
    "addNewRecipient": "새 수신자 추가",
    "synchronizeQuickBooks": "퀵북 동기화",
    "create": "만들다",
    "newUser": "새로운 사용자",
    "generate": "생성하다",
    "wITHDRAW": "철회하다",
    "confirm": "확인하다",
    "cancel": "취소",
    "close": "닫다",
    "sendResetInstructions": "재설정 지침 보내기",
    "logIn": "로그인",
    "resetPassword": "암호를 재설정",
    "setPassword": "암호를 설정하세요",
    "signUp": "가입하기",
    "forgotPassword": "비밀번호를 잊으 셨나요",
    "backToLogin": "로그인으로 돌아가기",
    "enterOtp": "인증 코드 입력",
    "resendCode": "새 코드 보내기",
    "selectPayment": "지불 유형 선택",
    "scheduleRecurringTransaction": "반복 트랜잭션 예약",
    "forgotPassText": "계정과 연결된 이메일을 입력하면 비밀번호 재설정 지침이 포함된 이메일을 보내드립니다.",
    "unableToCP": "결제를 생성할 수 없습니다.",
    "plsSelectPaymentTyp": "지불 유형을 선택하십시오",
    "plsSelectFromAcc": "계정에서 선택하세요.",
    "selectPaymentPayment": "지불 유형을 선택하십시오",
    "enterEmail": "이메일 주소를 입력하세요.",
    "emailValidation": "이메일 주소는 320자를 초과할 수 없습니다.",
    "enterPhone": "유효한 전화 번호를 입력하세요",
    "enterAccNum": "계좌번호를 입력해주세요",
    "AccNum": "계좌번호를 입력해주세요",
    "selectRecipientName": "받는 사람 이름을 선택하세요.",
    "enterAmounttoTransfer": "이체할 금액을 입력해주세요",
    "enterCurrency": "이체할 통화를 입력하세요.",
    "selectDirection": "방향을 선택하세요.",
    "selectPaymentDate": "결제일을 선택해주세요",
    "selectPaymentdate": "결제일을 선택해주세요",
    "selectServiceType": "서비스 유형을 선택하세요.",
    "selectFrequency": "빈도를 선택하세요.",
    "enterAmount": "금액을 입력하세요.",
    "selectTxnDate": "거래 날짜를 선택하세요.",
    "selectRecipientType": "받는 사람 유형을 선택하세요.",
    "enterEmailAddress": "이메일 주소를 입력하세요.",
    "validEmail": "유효한 이메일 주소를 입력하세요",
    "EmailValidation": "이메일 주소는 320자를 초과할 수 없습니다.",
    "selectFrmAcc": "계정에서 선택하세요.",
    "invoiceAlpha": "인보이스 번호는 영숫자여야 합니다.",
    "enterInvoiceNum": "인보이스 번호를 입력하세요.",
    "invoiceValidation": "인보이스 번호는 150자를 초과할 수 없습니다.",
    "amountToTransfer": "이체할 금액을 입력해주세요",
    "valueForMemo": "메모 값을 입력하십시오.",
    "memoValidation": "메모는 255자를 초과할 수 없습니다.",
    "selectPaymentType": "지불 유형을 선택하십시오",
    "enterName": "이름을 입력하세요.",
    "ValidName": "유효한 이름을 입력하십시오",
    "nameValidation": "이름은 3자 이상이어야 합니다.",
    "Name": "이름을 입력하세요.",
    "validname": "유효한 이름을 입력하십시오",
    "nameValid": "이름은 3자 이상이어야 합니다.",
    "enterZipCode": "우편번호를 입력하세요.",
    "validZipCode": "유효한 우편번호를 입력하세요.",
    "validzipcode": "우편번호는 5자리 미만일 수 없습니다.",
    "validAccName": "계정 이름을 입력하세요.",
    "enterValidAccName": "유효한 계정 이름을 입력하십시오",
    "maxAccName": "계정 이름은 20자리를 초과할 수 없습니다.",
    "minAccName": "계정 이름은 3자리 미만일 수 없습니다.",
    "accNum": "계좌번호를 입력해주세요.",
    "validAccNum": "유효한 계좌 번호를 입력하십시오",
    "maxAccNum": "계좌번호는 20자리를 넘을 수 없습니다.",
    "japanMaxAccNum": "계좌번호는 35자리를 넘을 수 없습니다.",
    "japanMaxBankName": "은행 이름은 35자리를 초과할 수 없습니다.",
    "japanMinSwiftCode": "Swift 코드는 최소 8자여야 합니다.",
    "japanMinAddress": "주소는 2자 이상이어야 합니다.",
    "japanMaxCity": "도시는 35자를 초과할 수 없습니다.",
    "minAccNum": "계좌번호는 4자리 이상이어야 합니다.",
    "routingNum": "라우팅 번호를 입력하십시오.",
    "validRoutingNum": "유효한 라우팅 번호를 입력하십시오",
    "minRoutingNum": "라우팅 번호는 9자리보다 작을 수 없습니다.",
    "ValidRoutingNum": "유효한 라우팅 번호를 입력하십시오.",
    "Acctype": "계정 유형을 선택하십시오.",
    "validAddress": "유효한 주소를 입력하십시오.",
    "maxAddress": "주소는 400자를 초과할 수 없습니다.",
    "minAddress": "주소는 5자 미만일 수 없습니다.",
    "City": "귀하의 도시를 입력하십시오.",
    "maxCity": "도시는 60자를 초과할 수 없습니다.",
    "countryName": "국가 이름을 선택하세요.",
    "stateName": "유효한 주를 입력하십시오",
    "validzipCode": "올바른 우편 번호를 입력 해주세요",
    "selectVerificationType": "확인 유형을 선택하십시오.",
    "debitAmnt": "출금금액을 입력해주세요.",
    "creditAmnt": "크레딧 금액을 입력하세요.",
    "CreditAmount": "크레딧 금액을 입력하세요.",
    "AccName": "계정 이름을 입력하세요.",
    "recipienttype": "받는 사람 유형을 선택하세요.",
    "accNumber": "계좌번호를 입력해주세요.",
    "validAccnum": "유효한 계좌 번호를 입력하십시오",
    "maxAccNumber": "계좌번호는 20자리를 넘을 수 없습니다.",
    "minAccNumber": "계좌번호는 4자리 이상이어야 합니다.",
    "beneficiaryBankcode": "수취인 은행 코드를 입력하세요.",
    "minRoutingNumber": "라우팅 번호는 9자리보다 작을 수 없습니다.",
    "acountNumber": "계좌번호를 입력해주세요.",
    "maxAccnum": "계좌번호는 20자리를 넘을 수 없습니다.",
    "minAccnum": "계좌번호는 4자리 이상이어야 합니다.",
    "routingnum": "라우팅 번호를 입력하십시오.",
    "maxRoutingnum": "라우팅 번호는 9자리보다 작을 수 없습니다.",
    "acctype": "계정 유형을 선택하십시오.",
    "accountNum": "계좌번호를 입력해주세요.",
    "validaccountNum": "유효한 계좌 번호를 입력하십시오",
    "maxAccountNum": "계좌번호는 20자리를 넘을 수 없습니다.",
    "minAccountNum": "계좌번호는 4자리 이상이어야 합니다.",
    "enterRoutingNum": "라우팅 번호를 입력하십시오.",
    "minRouting": "라우팅 번호는 9자리보다 작을 수 없습니다.",
    "enterAddressLine1": "주소 입력란 1을 입력하십시오.",
    "maxAddrss": "주소는 100자를 초과할 수 없습니다.",
    "minAddrss": "주소는 5자 미만일 수 없습니다.",
    "enterCity": "도시를 입력하십시오",
    "selectCountryName": "국가 이름을 선택하십시오.",
    "selectStateName": "주 이름을 선택하세요.",
    "validZipcode": "올바른 우편 번호를 입력 해주세요",
    "valdAccnum": "유효한 계좌 번호를 입력하십시오",
    "countryname": "국가 이름을 선택하십시오.",
    "statename": "유효한 주 이름을 입력하십시오",
    "selectpaymentMethod": "결제 방법을 선택하세요.",
    "validPhone": "유효한 전화 번호를 입력하세요",
    "inpFname": "이름을 입력하세요.",
    "inpLname": "성을 입력하세요.",
    "inpEmail": "이메일을 입력해주세요",
    "valiemail": "유효한 이메일을 입력하세요",
    "validFname": "유효한 이름을 입력하세요",
    "maxFname": "이름은 50자를 초과할 수 없습니다.",
    "minFname": "최소 3자가 필요합니다.",
    "validLname": "유효한 성을 입력하세요.",
    "maxLname": "성은 50자를 초과할 수 없습니다.",
    "minLname": "최소 3자가 필요합니다.",
    "phoneNum": "전화번호를 입력하세요.",
    "validPhoneNum": "유효한 전화 번호를 입력하세요",
    "maxPhone": "전화번호는 10자리를 넘을 수 없습니다.",
    "minPhone": "전화번호는 10자리여야 합니다.",
    "fromAcc": "계정에서 선택하세요.",
    "selectstartDate": "시작일을 선택하세요.",
    "newPsw": "새 비밀번호가 저장됨",
    "unablePsw": "비밀번호를 업데이트할 수 없습니다.",
    "inpOldPsw": "이전 비밀번호를 입력하세요.",
    "inpNewPsw": "새 비밀번호를 입력하세요",
    "maxPsw": "비밀번호는 8자 이상이어야 합니다.",
    "newPassword": "새 비밀번호를 확인하세요.",
    "result": "결과",
    "buyOrsell": "구매 또는 판매를 선택하십시오",
    "frmAccNum": "계좌번호에서 선택하세요.",
    "selectaccNum": "계좌번호를 선택해주세요",
    "paymentInst": "결제 수단을 선택하세요.",
    "instType": "악기 유형을 선택하세요.",
    "insufficientFunds": "자금 부족",
    "unablePayment": "결제를 생성할 수 없습니다.",
    "selectfundingType": "카드 및 계정을 선택하세요.",
    "amntTrans": "이체할 금액을 입력해주세요",
    "counterParty": "상대방을 선택해주세요.",
    "purposePayment": "결제 목적을 선택해주세요.",
    "toAcc": "계정으로 선택하세요",
    "validUrl": "유효한 URL을 입력하세요.",
    "all": "모두",
    "loading": "로드 중...",
    "transactions": "거래",
    "noAccountData": "계정 데이터 없음",
    "creditTransfer": "학점 이전",
    "noTransactionData": "거래 데이터 없음",
    "productCredit": "제품 크레딧",
    "refresh": "새로 고치다",
    "UWallets": "유월렛",
    "UWalletNumber": "유월렛 번호",
    "CreditID": "신용 ID",
    "InstrumentType": "악기 유형",
    "SelectCurrency": "통화 선택",
    "select": "고르다",
    "CardType": "카드 종류",
    "pleaseselecttheCardtype": "카드 종류를 선택해주세요",
    "CardNumber": "카드 번호",
    "ExpirationDate": "만료 날짜",
    "CVV": "CVV",
    "validCardNumber": "유효한 카드 번호를 입력하십시오",
    "failedAddCard": "카드를 추가하지 못했습니다.",
    "cardAddedSuccessfully": "카드가 성공적으로 추가되었습니다.",
    "validExpiration": "유효한 만료 날짜를 입력하십시오",
    "validCVV": "유효한 CVV 코드를 입력하십시오",
    "GoBack": "돌아가기",
    "Confirmation": "확인",
    "NoPaymentInstrumentData": "결제 수단을 찾을 수 없음",
    "deactivatePayment": "선택한 결제 수단을 비활성화하시겠습니까?",
    "accountVerified": "성공 !! 계정 정보가 성공적으로 확인되었습니다!!",
    "accountNotVerified": "죄송해요 !! 계정 세부정보를 확인할 수 없습니다.",
    "validAmount": "유효한 금액을 입력하세요.",
    "noConnectorFound": "결제 수단을 찾을 수 없음",
    "noCountryFound": "국가 목록을 찾을 수 없음",
    "wentWrongText": "문제가 발생했습니다.",
    "noStateFound": "상태 목록을 찾을 수 없음",
    "noteText": "참고: 시험 입금 프로세스를 사용한 은행 계좌 확인에는 영업일 기준 최대 5일이 소요됩니다.",
    "accountalreadyExists": "계좌번호가 이미 존재합니다.",
    "FeatureType": "기능 유형",
    "validFeatureType": "기능 유형을 선택하십시오.",
    "NoPaymentChannel": "결제 채널을 찾을 수 없음",
    "bankAddedsuccess": "은행 계좌가 성공적으로 추가되었습니다.",
    "bankAddedFailed": "은행 계좌를 추가하지 못했습니다.",
    "limitBreach": "제한 위반",
    "withdrawMethodSelect": "인출 방법 선택",
    "totalAmount": "총액",
    "decimalpointErr": "소수점 앞 최대 7자리 허용.",
    "termsandCondition": "이용 약관에 동의해야 합니다.",
    "agreeText": "동의합니다",
    "termsText": "이용 약관.",
    "minAmountTextAch": "최소 인출 금액은",
    "withdrawFeeText1": "인출 수수료",
    "withdrawFeeText2": "적용됩니다.",
    "maxAmountWithdrawTxt1": "최대 인출 금액은",
    "maxAmountWithdrawTxt2": "요청 당.",
    "withdrawSuccess": "출금 성공!",
    "createAnotherPay": "다른 결제 생성",
    "selectCurrency": "통화를 선택하세요.",
    "lowPayquickerBalance": "페이퀵커 잔액이 너무 낮아 결제할 수 없습니다.",
    "SelectPaymentMethod": "결제 수단 선택",
    "availablePayBalance": "사용 가능한 페이퀵커 잔액",
    "fundsLoadedSuccess": "자금이 성공적으로 로드되었습니다!",
    "invalidAccDetails": "잘못된 계정 정보",
    "insufficientAccBalance": "불충분한 계정 잔액",
    "invalidRecuDate": "잘못된 반복 날짜를 선택했습니다.",
    "invalidTONum": "유효하지 않은 계정 번호",
    "invalidTrans": "잘못된 거래",
    "counterPartyNotFound": "상대방을 찾을 수 없음",
    "ofacFailed": "OFAC 확인 실패",
    "interSORFail": "SOR 간 거래 불가",
    "invalidRoutingNum": "유효하지 않은 라우팅 번호",
    "requestedServiceNotAvail": "현재 요청한 서비스를 사용할 수 없습니다.",
    "limitExceedGlobal": "전역 한도 초과",
    "clientLimitExceed": "클라이언트 거래 한도 초과",
    "dailyLimitExceed": "일일 거래 한도 초과",
    "invalidRecdetails": "잘못된 수신자 세부정보",
    "unabletoQuote": "견적을 받을 수 없습니다.",
    "enterRecipntPhone": "받는 사람 전화번호 입력",
    "enterAccNumber": "수취인 계좌번호 입력",
    "accountNotMatch": "계좌번호가 일치하지 않음",
    "recipientAccount": "받는 사람 계정",
    "convertedAmount": "환산금액",
    "paymentSuccess": "결제가 성공적으로 생성되었습니다!",
    "orderNumber": "주문 번호",
    "showing": "전시",
    "of": "의",
    "refNumber": "참조 번호",
    "details": "세부",
    "NoTransactionsFound": "거래를 찾을 수 없음",
    "conntedTOQB": "퀵북에 성공적으로 연결됨",
    "QuoteConvertedAmount": "총 견적 전환 금액",
    "QBConnectFailed": "퀵북에 연결하지 못했습니다.",
    "conenctingToQB": "퀵북에 연결 중입니다. 잠시만 기다려 주십시오...",
    "synchronizeFailed": "퀵북 동기화 실패",
    "synchronizeSuccess": "성공적으로 동기화된 퀵북",
    "syncQBText": "퀵북과 동기화 상태 유지",
    "accountConnectedToQB": "퀵북에 이미 연결된 계정",
    "Password": "비밀번호",
    "profileChangesSaved": "프로필 변경 사항이 저장되었습니다.",
    "Timezone": "시간대",
    "Locale": "장소",
    "passwordUpdateSuccess": "비밀번호 변경 사항이 성공적으로 저장되었습니다.",
    "passwordReqText": "암호는 지정된 요구 사항을 따라야 합니다.",
    "passwordNotMatch": "입력한 두 개의 비밀번호가 일치하지 않습니다",
    "newUserSuccess": "새 사용자가 성공적으로 생성되었습니다.",
    "addNewUser": "새 사용자 추가",
    "ssoReq": "SSO 필요",
    "logoutTxt": "성공적으로 로그아웃되었습니다",
    "updatePaymentSuccess": "성공! 결제 수단을 성공적으로 업데이트했습니다.",
    "updatePaymentFailed": "결제 수단을 업데이트하지 못했습니다.",
    "noDataFoundFound": "데이터가 없습니다",
    "submit": "제출하다",
    "completed": "완전한",
    "failed": "실패한",
    "pending": "보류 중",
    "sent": "전송된",
    "SessionExpired": "세션이 만료됨",
    "Uwallet": "유월렛",
    "UWalletfunding": "유월렛 펀딩",
    "debit": "차변",
    "credit": "신용 거래",
    "CARD": "카드",
    "BANK": "은행",
    "active": "활동적인",
    "ccintl": "신용카드 인터내셔널",
    "amex_dis": "아멕스/디스커버",
    "amex_card_text": "아멕스 카드 추가",
    "nodata": "데이터 없음",
    "note": "메모",
    "load_fund_via_card": "카드로 자금 충전",
    "load_fund_via_ach": "ACH를 통한 자금 충전",
    "load_fund_via_connector": "커넥터를 통한 자금 로드",
    "p2m_via_connector": "커넥터를 통한 P2M",
    "creditAdjustment": "신용 조정",
    "debitAdjustment": "차변 조정",
    "startDateText": "시작일",
    "endDateText": "종료 날짜",
    "payIn": "지불하다",
    "payOut": "지불금",
    "checking": "확인 중",
    "saving": "절약",
    "verify": "확인하다",
    "reset": "초기화",
    "today": "오늘",
    "a2a_reversal": "A2A 반전",
    "credit_point": "크레딧 포인트",
    "commission_payout_via_ach": "ACH를 통한 커미션 지급",
    "commission_payout_via_uWallet": "UWallet을 통한 커미션 지급",
    "commission_payout_via_payquicker": "페이퀵커를 통한 커미션 지급",
    "withdraw_via_ach": "ACH를 통해 자금 인출",
    "withdraw_via_payquicker": "페이퀵커를 통해 자금 인출",
    "credit_reversal": "신용 취소",
    "load_fund_via_card_reversal": "카드 취소를 통한 자금 충전",
    "uwallet_funding_reversal": "유월렛 펀딩 취소",
    "processing": "처리",
    "declined": "거부됨",
    "onHold": "보류 중",
    "scheduled": "예정",
    "cancelled": "취소 된",
    "beneficiaryName": "수취인 이름",
    "b_b_code": "은행 코드 및 지점 코드",
    "validBankCode": "유효한 코드를 입력하세요",
    "minBankCode": "코드는 7자리 미만일 수 없습니다.",
    "load_fund_wallet": "자금 지갑 로드",
    "ach_transfer": "ACH 전송",
    "mater_visa": "마스터카드/비자",
    "go_to_transaction": "거래로 이동",
    "p2m_reversal": "P2M 반전",
    "deposit_account_list": "예금 계좌 목록",
    "load_fund_via_ach_reversal": "ACH 반전을 통한 자금 로드",
    "load_fund_via_connector_reversal": "커넥터 반전을 통한 자금 로드",
    "KYC_Failed": "KYC 인증 실패",
    "updateKYCText": "KYC 세부 정보를 업데이트하시겠습니까?",
    "userType": "사용자 유형",
    "usertypeErr": "사용자 유형을 선택하십시오.",
    "recidencyNumberTxt": "주민등록번호",
    "validResNum": "주민등록번호 필수",
    "validresidencyNum": "유효한 주민번호를 입력하세요.",
    "flexText": "PayQuicker Flex",
    "flexTextVietnam": "Thanh toán linh hoạt",
    "withdrawalConfig": "출금 설정",
    "withdrawalConfiguration": "출금 구성",
    "addConfig": "구성 추가",
    "addFreequencyConfig": "주파수 구성 추가/편집",
    "ReceivingAccount": "수신 계정",
    "deactivateConfig": "선택한 구성을 비활성화하시겠습니까?",
    "updateConfigSuccess": "성공! 구성을 성공적으로 업데이트했습니다.",
    "updateConfigFailed": "구성을 업데이트하지 못했습니다.",
    "add_card_text": "신용카드 추가",
    "UWalletTransfer": "UWallet 전송",
    "daily": '일일',
    "weekly": "주간",
    "monthly": "월간 간행물",
    "biweekly": "격주 간행물",
    "immediate": "즉각적인",
    "percentage": "백분율",
    "percentError": "유효한 백분율을 입력하십시오",
    "toDistributor": "대리점에",
    "distributorId": "대리점 ID를 입력하세요.",
    "invalidDistributor": "유효한 대리점 ID를 입력하십시오",
    "distributorNotFound": "배포자를 찾을 수 없음",
    "distributorKYCError": "수신자 배포자가 확인되지 않음",
    "decimalNotPermit": "소수점 이하 자릿수는 허용되지 않습니다.",
    "paybyCard": "카드 결제",
    "validIDNum": "유효한 숫자를 입력하십시오",
    "swiftCode": "스위프트 코드",
    "validBankName": "유효한 은행 이름을 입력하세요.",
    "JAPANPAYOUT": "은행",
    "idNumber": "ID 번호",
    "commissionHistory": "수수료 내역",
    "CITCON WITHDRAWAL": "은행 출금",
    "validCityName": "올바른 도시 이름을 입력하세요.",
    "pendingApproval": "승인 보류 중",
    "purposeCode": "지불 목적",
    "plsSeletpurposeCode": "결제 목적을 선택해 주세요",
    "QualifyingCredit": "적격 학점",
    "tooltipAccountName": "이름과 성을 포함한 전체 이름을 입력하세요(개인인 경우). 예: John Smith 또는 전체 법적 회사 이름(회사인 경우). 예: ABC Limited",
    "tooltipAccountNumber": "은행계좌번호를 입력하세요",
    "tooltipBankCode": "은행코드를 입력하세요",
    "tooltipBranchCode": "지점코드를 입력하세요",
    "tooltipSwiftBic": "은행의 SWIFT BIC(은행 식별 코드)를 8자 또는 11자 길이로 입력하세요. SWIFT BIC가 8자에 불과한 경우 XXX를 추가하여 11자를 채우지 마세요.",
    "tooltipAddress": "거주지(개인인 경우) 또는 등록된(회사인 경우) 집 번호/이름 및 거리 이름을 입력하세요(예: 1 Main Street)",
    "tooltipCity": "거주지(개인인 경우) 또는 등록된 주소(회사인 경우)의 도시나 마을을 입력하세요(예: 런던)",
    "tooltipZipcode": "거주지(개인인 경우) 또는 등록된 주소(회사인 경우)의 우편번호를 입력하세요.",
    "tooltipCountry": "거주지(개인인 경우) 또는 등록된 주소(회사인 경우)를 선택하세요.",
    "tooltipDOB": "생년월일을 선택해주세요. (개인인 경우)",
    "tooltipBankName": "은행명을 입력해주세요.",
    "bankCode": "은행 코드",
    "branchCode": "지점 코드",
    "dob": "생일",
    "selct_currency_deposit": "화폐",
    "pc_from_text": "에서",
    "pc_to_text": "에게",
    "pc_amount_text": "양",
    "CNY PAYOUT": "Bank (WP)",
    "accountHolderaddress": "계좌 소유자의 주소",
    "accountHolderName": "계좌 소유자 이름",
    "branchName": "지점명",
    "continue": "계속하다",
    "validBranchName": "유효한 지점 이름을 입력하세요.",
    "SOLU": "은행 출금",
    "cardUpdatedSuccessfully": "카드가 업데이트되었습니다.",
    "reason": "이유",
    "failedUpdateCard": "카드를 업데이트하지 못했습니다.",
    "requiredError": "이 필드는 필수입니다",
    "kycNotVerified": "KYC가 확인되지 않았습니다.",
    "VND PAYOUT": "철회하다 WP",
    "phoneNumberAliWP": "86 XXX XXXX XXXX",
    "phoneTooltip": "수혜자의 전화번호를 입력해 주세요 (예: 86 XXX XXXX XXXX)",
    "emailTooltip": "수혜자의 이메일 주소를 입력해 주세요",
    "ALIPAY PAYOUT WP": "Alipay",

    "enterValidAccNameWithLimit": "Enter Valid Payee name with minimum 2 and maximum 70 characters",
    "enterValidAccNumWithLimit": "Enter Valid account number with minimum 4 and maximum 17 characters",
    "enterValidRoutingNumWithLimit": "Enter Valid routing number",
    "enterValidAddressWithLimit": "Enter Valid Address with minimum 2 and maximum 70 characters",
    "enterValidCityWithLimit": "Enter Valid City with minimum 2 and maximum 35 characters",
    "enterValidZipcodeWithLimit": "Enter Valid Zip code with minimum 2 and maximum 10 characters",

    "enterValidAccNameWithChinese": "Enter valid name in Chinese Characters",
    "enterValidBankNameWithChinese": "Enter valid Bank name in Chinese Characters",
    "enterValidBranchNameWithChinese": "Enter valid Branch name in Chinese Characters",
    "enterValidSoluAccNumWithLimit": "Enter Valid numeric account number maximum 17 characters.",
    "enterValidSoluPhoneNumWithLimit": "Enter Valid numeric phone number maximum 20 characters.",
    "enterValidSoluIDNumWithLimit": "Enter Valid alphanumeric ID number maximum 100 characters.",

    "enterValidAlipayPayeeNameWithLimit": "Enter Valid Payee name with minimum 2 and maximum 35 characters",
    "enterValidAlipayPhoneNumWithLimit": "Enter Valid numeric phone number with minimum = 5 maximum 34 characters.",
    "enterValidAlipayEmailWithLimit": "Enter Valid Email address  with minimum = 6 maximum 100 characters.",

    "enterValidThanhAccNameWithChinese": "Enter Valid  account name with minimum 1 and maximum 140 characters.",
    "enterValidThanhAccNumWithLimit": "Enter Valid  account number  with minimum 1 and maximum 30 characters.",
    "enterValidThanhSwiftCodeWithLimit": "Enter Valid swift code with minimum 8 and maximum 11 characters.",
    "enterValidThanhAddressWithLimit": "Enter Valid  address  with minimum 2 and maximum 35 characters.",

    "enterValidBeneficiaryNameWithLimit": "Enter valid name with maximum 20 characters.",
    "enterValidTWDAccNumWithLimit": "Enter valid account number with maximum 20 characters.",
    "enterValidTWDBranchCodeWithLimit": "Enter valid branch code with maximum 7 characters.",

    "enterValidMYRAccNameWithLimit": "Enter valid name with minimum = 2 and maximum 50 characters.",
    "enterValidMYRAccNumWithLimit": "Enter valid numeric account number with maximum 16 characters.",

    "enterValidTHBAccNameWithLimit": "Enter valid name with maximum 35 characters.",
    "enterValidTHBAccNumWithLimit": "Enter valid numeric account number  with  maximum 12 characters.",
    "enterValidTHBSwiftCodeWithLimit": "Enter valid swift code with maximum 11 characters.",
    "enterValidTHBAddressWithLimit": "Enter valid Address with minimum = 5 and maximum 35 characters.",
    "enterValidTHBCityWithLimit": "Enter valid city with maximum 60 characters.",
    "enterValidTHBZipcodeWithLimit": "Enter valid zip code with minimum = 5 and maximum 10 characters.",

    "enterValidGBPPayeeNameWithLimit": "Enter valid name with Minimum 3 and maximum 50 characters.",
    "enterValidGBPIbanWithLimit": "Enter valid Iban with maximum 22 characters.",
    "enterValidGBPBankNameWithLimit": "Enter valid Bank name with Minimum = 1 and maximum 50 characters.",

    "enterValidEURAccNameWithLimit": "Enter valid name with Minimum 1 and maximum 50 characters.",
    "enterValidEURAccNumWithLimit": "Enter valid numeric account number  with  Minimum 1 and maximum 35 characters.",
    "enterValidEURBankNameWithLimit": "Enter valid Bank name with Minimum = 2 and maximum 10 characters.",

    "enterValidJPYBankNameWithLimit": "Enter valid bank name with  maximum 35 characters.",
}